/* eslint-disable */
export default [
    { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Admin/view/Dashboard.vue'),
      meta: {
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        }
      }
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/Admin/view/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/domain',
      name: 'auth-domain',
      component: () => import('@/views/Admin/view/LoginDomain.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/list/:module/:submodule',
      name: 'list',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'List Table',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/detail/:module/:submodule/:oid',
      name: 'detail-view',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Detail View',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/write/medicalrecord/:consultation',
      name: 'write-medrec',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Write Medical Record',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/invoice/:module/:submodule/:oid',
      name: 'global-invoice',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Invoice',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/labtest/:oid',
      name: 'labtest',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Input LabTest',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/cart/:module/:submodule/:oid',
      name: 'global-cart',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Cart',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/accountsetting',
      name: 'account-setting',
      component: () => import('@/views/Admin/view/AccountSetting.vue'),
      meta: {
        pageTitle: 'Detail View',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Todo',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/kanban',
      name: 'kanban',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Todo',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/form/:module/:submodule',
      name: 'form',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Form View',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/print/labtest/:oid',
      name: 'print-labresult',
      component: () => import('@/views/Admin/print/LabResult.vue'),
      meta: {
        pageTitle: 'Lab Result',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/role',
      name: 'role',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Role',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/ticket',
      name: 'ticket',
      component: () => import('@/views/Admin/Container.vue'),
      meta: {
        pageTitle: 'Ticket',
        progress: {
          func: [
            {call: 'color', modifier: 'temp', argument: '#00ac66'},
            {call: 'fail', modifier: 'temp', argument: '#6e0000'},
            {call: 'location', modifier: 'temp', argument: 'top'},
            {call: 'transition', modifier: 'temp', argument: {speed: '1.5s', opacity: '1s', termination: 400}}
          ]
        },
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/Admin/view/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
]
/* eslint-enable */
