import Vue from 'vue'

// axios
import axios from 'axios'

/* eslint-disable */
const axiosIns = axios.create({
  baseURL: localStorage.getItem('apiUrl'),
  headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
})

Vue.prototype.$http = axiosIns
/* eslint-enable */

export default axiosIns
