import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

import VueProgressBar from 'vue-progressbar'
import VueNativeNotification from 'vue-native-notification'
import i18n from '@/libs/i18n'
import axios from '@/libs/axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
// import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

const options = {
  color: '#00ac66',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
}

Vue.use(VueProgressBar, options)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// Pusher
Vue.use(require('vue-pusher'), {
  api_key: '4b7f5a681993cb5aa504',
  options: {
    cluster: 'ap1',
    encrypted: true,
  },
})

// Native Notification
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

/* eslint-disable */
Vue.mixin({
  methods: {
    loaderState(state) {
      document.getElementById('loading-bg').style.display = (state) ? 'block' : 'none'
    },
    errorHandler(error) {
      if (typeof error === 'object') error = error.message
      this.$store.state.error = error
    },
    errorAuth(type) {
      let msg = ''
      if(type == 'TOKEN_EXPIRED') msg = 'Your login session was expired, Please login again.'
      else if(type == 'COMPANY_DISABLED') msg = 'Your company has been disabled'
      this.$swal({
        text: msg,
        imageUrl: require('@/assets/images/slider/errorauth.png'),
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: 'Custom image',
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(res => {
        if(res.value) {
          this.logout()
        }
      })
    },
    logout() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$http.post('auth/logout',{ Username : userData.Username })
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('menu')
      localStorage.removeItem('appName')
      localStorage.removeItem('apiUrl')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-domain' })
    },
    portalApi() {
      return 'https://portal.divideindonesia.com/api'
    }
  },
})

// check update
axios.get('general/init/checkupdate')
.then(res => {
  if(res.data.update) {
    document.getElementById('updating-bg').style.display = 'block'

    axios.get('general/init/update?version='+res.data.version)
    .then(res => {
      document.getElementById('updating-bg').style.display = 'none'
    })
    .catch(err => {
      document.getElementById('updating-bg').style.display = 'block'
    })
  }
})
/* eslint-enable */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
